import { renderSchematic } from "@enginehub/schematicwebviewer";
const https = require('https')
//const url = require('url');

const CORS_ANYWHERE = "https://cors.bprev.io/"

//console.log("test");

//console.log(window.location.search);
const par = new URLSearchParams(window.location.search);
if (!(par.has("s") && par.has("a") && par.has("f"))) throw new Error("Missing Parameters!");
if (!(parseInt(par.get("s")) > 0 && parseInt(par.get("a")) > 0 && parseInt(par.get("f")) > 0)) throw new Error("Check Parameters!");

const options = {
  hostname: 'cors.bprev.io',
  method: 'GET',
  port: 443,
  path: '/https://panel.bprev.io/server/' + par.get("s") + '/arena/' + par.get("a") + '/floor/' + par.get("f") + '/get',
  headers: {
    "X-Requested-With": "BP-Schem-Viewer",
    "Origin": "https://schem.bprev.io"
  }
}

https.get(options, resp => {
  let data = '';
  resp.on('data', (chunk) => { data += chunk; });
  resp.on('end', () => {
    renderSchematic(document.querySelector("#schem"), data, {
      size: 500,
      orbit: false,
      renderArrow: false,
      renderBars: false,
      corsBypassUrl: CORS_ANYWHERE,
      backgroundColor: "#000000"
    });
  });
}).on('error', err => {
  console.log('Error: ', err.message);
});